<template>
  <div ref="pdd" class="pdd">
    <div class="needsclick" ref="copyBtn" style="display: none"></div>
    <div class="top">
      <img src="https://cdn.cailu88.com/jingxuanshi/client_pdd@2x.png"/>
    </div>
    <div class="bottom">
      <div class="image">
        <img src="https://cdn.cailu88.com/jingxuanshi/client_subsidies_button@2x.png"
             v-if="channel !== 'weixin' && channel !== 'zfb'" @click="handleGetUrl"/>
      </div>
      <div class="content">
        <div style="padding: 20px 28px">
          <p class="copy">{{ content }}</p>
          <van-button @click="copyText($event)">复制链接</van-button>
          <p class="text">
            <span>复制文案</span>
            <van-icon name="arrow"/>
            <span>好友下单</span>
            <van-icon name="arrow"/>
            <span>你赚佣金</span>
          </p>
        </div>
      </div>
    </div>
    <Loading :click="click"></Loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { Button, Icon, Toast } from 'vant'
import Loading from '../../components/loading'
import baseUrl from '@/utils/baseUrl'
import clipboard from '@/utils/copy'

const api = require('../../utils/api').api

Vue.use(Button)
Vue.use(Icon)
Vue.use(Toast)

export default {
  name: 'pdd',
  data () {
    return {
      token: '',
      channel: '',
      version: '',
      pddUrl: 'https://mobile.yangkeduo.com/promotion_subject.html?_pdd_fs=1&_pdd_tc=ffffff&_pdd_sbs=1&type=2&subject_id=51658',
      url: '',
      copyContent: '',
      content: '拼多多双十一主会场，限时秒杀，无套路降到底，真实惠不用算！抢购链接：https://p.pinduoduo.com/yyrbwpqs',
      click: true,
      id: '39998',
    }
  },
  components: {
    Loading,
  },
  created () {
    this.token = this.$route.query.token
    this.version = this.$route.query.version
    this.channel = this.$route.query.channel
  },
  mounted () {
    this.click = false
    this.$axios.post(api.strategyUrl, {
      pddActivityMaterialId: this.id,
      initUrl: this.pddUrl
    }).then(res => {
      this.url = res.data.data.appSpreadUrl
      this.copyContent = res.data.data.shareContent
      this.click = true
    }).catch(err => {
      this.click = true
    })
  },

  methods: {

    //复制
    copyText (event) {
      this.content = this.copyContent
      Toast.success({
        duration: 500, // 持续展示 toast
        message: '复制成功'
      })
      clipboard(this.copyContent, event)
    },

    // 进入会场
    jump () {
      let event = 'openApp'
      let param = {
        url: this.url
      }
      this.$h5AppLink(event, param)
    },

    // 获取url
    handleGetUrl () {
      if (!this.click) {
        return false
      }
      this.click = false
      if (this.url) {
        this.click = true
        const param = {
          url: this.url
        }
        this.$h5AppLink('openWebview', param)
        // setTimeout(() => {
        //   // this.jump();
        //   window.open(this.url)
        // })
        return
      }
      this.$axios.post(api.strategyUrl, {
        pddActivityMaterialId: this.id,
        initUrl: this.pddUrl
      }).then(res => {
        this.url = res.data.data.spreadUrl
        this.copyContent = res.data.data.shareContent
        this.click = true
        const param = {
          url: this.url
        }
        this.$h5AppLink('openWebview', param)
        // setTimeout(() => {
        //   // this.jump();
        //   window.open(this.url)
        // })
      }).catch(err => {
        Toast.fail(err.data.message)
        this.click = true
        this.isLogin()
      })
    },

    // 登陆
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/pdd`
      }
      this.$h5AppLink(event, param)
    },

  },
}
</script>
<style lang="less" scoped>
.pdd {
  min-height: 100vh;
  background: #D40101;

  .top {
    line-height: 0;

    img {
      width: 100%;
    }
  }

  .bottom {
    width: 100%;
    text-align: center;

    .image {
      margin-bottom: 36px;

      img {
        width: 275px;
      }
    }

    .content {
      margin: 0 auto;
      width: 340px;
      background: #FFFFFF;
      border-radius: 12px;

      div {
        display: flex;
        flex-direction: column;
      }

      p {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }

      .copy {
        margin-bottom: 20px;
        line-height: 21px;
        text-align: left;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 3; //想显示多少行就给多大值
        //-webkit-box-orient: vertical;
      }

      .van-button {
        margin: 0 auto;
        width: 250px;
        height: 50px;
        background: #D40101;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        border-radius: 25px;
        border: none;
      }

      .text {
        margin-top: 15px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #D40101;
        line-height: 20px;

        .van-icon {
          margin: 0 12px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
